export const Wallet = {
  supplyLabs: {
    shortname: "supplyLabs",
    token: window.chtken,
    color: {
      primary: "#1c2f57",
      secondary: "#fff",
    },
  },
};
