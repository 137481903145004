import styled, { createGlobalStyle } from "styled-components";
import ButtonStyle from "@material-ui/core/Button";
import IconButtonStyle from "@material-ui/core/IconButton";
import MulishLight from 'assets/fonts/Mulish/static/Mulish-Light.ttf'
import MulishBold from 'assets/fonts/Mulish/static/Mulish-Bold.ttf'
import MulishNormal from 'assets/fonts/Mulish/static/Mulish-Regular.ttf'
export const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "Mulish";
    font-weight: normal;
    src: url(${MulishNormal}) format('opentype');
  }

  @font-face {
    font-family: "Mulish";
    font-weight: bold;
    src: url(${MulishBold}) format('opentype');
  }

  @font-face {
    font-family: "Mulish";
    font-weight: 300;
    src: url(${MulishLight}) format('opentype');
  }
    #digitalk-chat {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Mulish', sans-serif;
        font-size: 14px;
    }
    
    #digitalk-chat {
        display: flex;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
    }
    
    
    #digitalk-chat .hidden {
        display: none;
    }
`;

export const ContainerButton = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    align-items: flex-end;
    right: 32px;
    bottom: 20px;
    z-index: 99999;
`

export const Button = styled(ButtonStyle)`
  && {
    width: 330px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #044bd6;
    color: #fff;
    font-weight: 600;
    &:hover {
      background-color: #1c2f57;
      color: #fff;
    }

    &.hidden {
      display: none;
    }

    span {
      font-size: 12px;
      text-transform: none;

      svg {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
  }
`;

export const IconButton = styled(IconButtonStyle)`
  && {
    color: #000;
    svg {
      height: 13px;
    }
  }
`;

export const Header = styled.div`
  color: #ffffff;
  display: flex;
  height: 45px;
  justify-content: space-between;
  padding: 0 6px 0 33px;
  align-items: center;
  background: #fff;
  border-bottom: 3px solid #1c2f57;  
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 18px;
  }
  img {
    height: 40px;
  }
`;

export const Container = styled.div`
  width: 350px;
  max-width: 100%;
  border-radius: 5px;
  overflow: hidden;
  height: 608px;
  min-height: 608px;
  max-height: 100%;
  background: #ffffff;
  position: fixed;
  right: 32px;
  bottom: 32px;
  filter: drop-shadow(0px 30px 40px rgba(0, 0, 0, 0.15));
  @media (max-height: 540px) {
    bottom: 0;
    max-height: 100%;
  }
  @media (max-width: 500px) {
    height: 100vh;
    width: 100vw;
    border-radius: 0;
    right: 0;
    bottom: 0;
  }
`;
