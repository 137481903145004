import "react-toastify/dist/ReactToastify.css";
import { GlobalStyle, Button, ContainerButton } from "./style";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Wallet } from "config";
import { ReactComponent as ChatIcon } from "assets/images/brands/whatsapp.svg";

const pathname = "supplyLabs";
const currWallet = Wallet[pathname];
const theme = createMuiTheme({
  palette: {
    primary: {
      main: currWallet.color.primary,
    },
    secondary: {
      main: currWallet.color.secondary,
    },
  },
});

function App() {

  const handleSendWhatsapp = (phone) => {
    let link = document.createElement('a')
					link.href = ` https://wa.me//${phone}?text=Oi!%20Estou%20com%20d%C3%BAvidas%20na%20plataforma%2C%20pode%20me%20ajudar%3F`
					link.target = '_blank'
					link.style.display = 'none'
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ContainerButton>
       <Button
          style={{ borderRadius: "15px 2px 15px 15px", padding: '0',minWidth: 'unset', width: "100px", height: "35px" }}
          variant="contained"
          classes={{ label: "button_chat" }}
          color="primary"
          onClick={() => handleSendWhatsapp("5511955811556")}
        >
          <ChatIcon style={{ width: "17px", height: "17px", marginRight: "5px"}}></ChatIcon>Suporte
        </Button>
      </ContainerButton>
    </ThemeProvider>
  );
}

export default App;
